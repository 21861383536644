<template>
  <div>
    <van-overlay class="wxtip" :show="showTip" @click="showTip = false">
      <span class="wxtip-icon"></span>
      <p class="wxtip-txt">点击右上角<br />选择在浏览器中打开</p>
    </van-overlay>
    <div style="width: 100%; height: 150px">
      <div style="text-align: center; margin-top: 50px">
        <van-icon name="checked" color="#1FC062" size="3em" />
      </div>
      <div
        style="
          margin-top: 30px;
          text-align: center;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
        "
      >
        律呗
      </div>
      <div
        style="
          margin-top: 10px;
          text-align: center;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 22px;
        "
      >
        ￥{{ realPrice }}
      </div>
    </div>
    <div style="width: 90%; height: 150px; margin: 5%">
      <van-divider custom-style="color:#DDDDDD" />
      <van-row>
        <van-col
          span="12"
          style="
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #666666;
          "
          >支付方式</van-col
        >
        <van-col
          span="12"
          style="
            text-align: right;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #666666;
          "
          >{{ payType }}</van-col
        >
      </van-row>
      <van-row custom-class="row-pay">
        <van-col
          span="12"
          style="
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #ffa51e;
          "
          >会员有效期</van-col
        >
        <van-col
          span="12"
          style="
            text-align: right;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #ffa51e;
          "
          >{{ memberEndTime }}</van-col
        >
      </van-row>
      <van-divider custom-style="color:#DDDDDD" />
    </div>
    <div class="footer">
      <van-button plain type="primary" size="large" round
        >应用商城搜索"律呗"app或在浏览器输入tool.farbun.com</van-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      realPrice: 0,
      payType: "",
      memberEndTime: "",
      phone: "",
      showTip: false,
    };
  },
  methods: {
    //初始化用户
    queryUser() {
      this.$axios
        .get(`${this.$base}/admin/lb/user?account=${this.phone}`)
        .then((res) => {
          if (res.code === 200) {
            if (res.data.memberEndTime) {
              this.memberEndTime = this.dayjs(res.data.memberEndTime).format(
                "YYYY-MM-DD"
              );
            } else {
              this.memberEndTime = this.dayjs().format("YYYY-MM-DD");
            }
          }
        });
    },
    //区分手机型号
    downloadApp() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var isIphone = sUserAgent.match(/iphone/i) == "iphone";
      var isHuawei = sUserAgent.match(/huawei/i) == "huawei";
      var isHonor = sUserAgent.match(/honor/i) == "honor";
      var isOppo = sUserAgent.match(/oppo/i) == "oppo";
      var isOppoR15 = sUserAgent.match(/pacm00/i) == "pacm00";
      var isVivo = sUserAgent.match(/vivo/i) == "vivo";
      var isXiaomi = sUserAgent.match(/mi\s/i) == "mi ";
      var isXiaomi2s = sUserAgent.match(/mix\s/i) == "mix ";
      var isRedmi = sUserAgent.match(/redmi/i) == "redmi";
      if (sUserAgent.match(/MicroMessenger/i) == "micromessenger") {
        //如果是微信环境，就弹出遮罩层
        this.showTip = true;
      } else if (isIphone) {
        window.location.href =
          "itms-appss://apps.apple.com/cn/app/id1438594194";
      } else if (isHuawei || isHonor) {
        window.location.href = "appmarket://details?id=com.farbun.fb";
      } else if (isOppo || isOppoR15) {
        window.location.href = "oppomarket://details?packagename=com.farbun.fb";
      } else if (isVivo) {
        window.location.href = "vivomarket://details?id=com.farbun.fb";
      } else if (isXiaomi || isRedmi || isXiaomi2s) {
        window.location.href = "mimarket://details?id=com.farbun.fb";
      } else {
        window.location.href =
          "https://sj.qq.com/myapp/detail.htm?apkName=com.farbun.fb";
      }
    },
  },
  mounted() {
    this.realPrice = this.$route.query.realPrice;
    this.payType = this.$route.query.payType === "WECHAT" ? "微信支付" : "";
    this.phone = this.$route.query.phone;
    this.queryUser();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 5;
  left: 0;
  right: 0;
  width: 90%;
  margin: 5%;
}
.wxtip-icon {
  width: 52px;
  height: 67px;
  background: url("../../static/weixin-tip.png") no-repeat;
  display: block;
  position: absolute;
  right: 30px;
  top: 20px;
}
.wxtip-txt {
  padding-top: 107px;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
}
.wxtip {
  text-align: center;
}
</style>